import http from '../../../utils/services/http'

export const uploadPlanilhaArrematante = (data) => {
  let url = `/api/importacao/arrematante`
  return http.post(url, data, {
    'timeout': 300000,
    // headers: {'Content-Type': 'multipart/form-data'}
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch((response) => {
      return Promise.reject(response)
    })
}

export const uploadAquivo = (data) => {
  let url = `/api/importacao/upload`
  return http.post(url, data, {
    'timeout': 300000,
    // headers: {'Content-Type': 'multipart/form-data'}
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch((response) => {
      return Promise.reject(response)
    })
}

export const importacoes = (tipo = null, entityId = null) => {
  let url = `/api/importacao`
  const extra = []
  if (tipo) {
    extra.push('tipo=' + tipo)
  }
  if (entityId) {
    extra.push('entityId=' + entityId)
  }
  if (extra && extra.length) {
    url = url + '?' + extra.join('&')
  }
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch((response) => {
      return Promise.reject(response)
    })
}

export const find = (id, limit, page, filtros) => {
  let url = `/api/importacao/${id}?page=` + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch((response) => {
      return Promise.reject(response)
    })
}

export const cancel = (id) => {
  let url = `/api/importacao/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch((response) => {
      return Promise.reject(response)
    })
}

export const iniciar = (id) => {
  let url = `/api/importacao/${id}/iniciar`
  return http.post(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch((response) => {
      return Promise.reject(response)
    })
}
